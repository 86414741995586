<template>
    <div class="container mt-5">


        <h2>
            <i class="bi bi-person-lines-fill"></i>
            Liste des utilisateurs
        </h2>

        <div class="my-3">

            <button
                type="button"
                tag="button"
                class="btn btn-outline-primary btn-sm mx-3"
                @click.prevent="createResource()"
              >
                <i class="bi bi-pencil-square"></i>
                New user
            </button>

            <button
                type="button"
                tag="button"
                class="btn btn-outline-secondary btn-sm mx-3"
                @click.prevent="changeLang()"
              >
                <i class="bi bi-pencil-square"></i>
                {{ state.nextLang }}
            </button>

        </div>

        <div class="my-3">
            <div class="form-check form-check-inline">
                <label class="form-check-label" for="filters">Filters on :</label>
            </div>
            <div class="form-check form-check-inline">
                <input v-model.number="useUsers.state.checkedUsers" class="form-check-input" type="checkbox" :value="parseInt(0)">
                <label class="form-check-label" for="news">News</label>
            </div>
            <div class="form-check form-check-inline">
                <input v-model.number="useUsers.state.checkedUsers" class="form-check-input" type="checkbox" :value="parseInt(1)">
                <label class="form-check-label" for="users">Users</label>
            </div>
            <div class="form-check form-check-inline">
                <input v-model.number="useUsers.state.checkedUsers" class="form-check-input" type="checkbox" :value="parseInt(2)">
                <label class="form-check-label" for="masters">Masters</label>
            </div>
            <div class="form-check form-check-inline">
                <input v-model.number="useUsers.state.checkedUsers" class="form-check-input" type="checkbox" :value="parseInt(3)">
                <label class="form-check-label" for="admins">Admins</label>
            </div>
        </div>

        <div
            v-show="useUsers.state.form.loading"
            class="spinner"
        >
            Loading users...
        </div>

        <!-- Display server error messages, from server validation -->
        <!-- Diplay alert message received from backend -->
        <MyModal
            v-bind:idProps="state.modal.alert"
        >
            <!--    Content for header slot     -->
            <template v-slot:header>
                {{ state.modal.header }}
            </template>

            <template v-slot:body>

                <AltertMessage
                    v-bind:data="useUsers.state.form.response"
                ></AltertMessage>

            </template>

        </MyModal>





        <div
            v-if="!useUsers.state.form.loading"
        >

            <div class="table-responsive">
                <table class="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th scope="col">id</th>
                            <th scope="col">name</th>
                            <th scope="col">email</th>
                            <th scope="col">role</th>
                            <th scope="col">created at</th>
                            <th scope="col">updated at</th>
                            <th scope="col">Edit</th>
                            <th scope="col">Validate</th>
                            <th scope="col">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="user in useUsers.state.selectedUsers"
                            v-bind:key="user"
                        >
                            <td>{{ user.id }}</td>
                            <td>{{ user.name }}</td>
                            <td>
                                <a v-bind:href="'mailto:' + user.email">
                                    {{ user.email }}
                                </a>
                            </td>
                            <td>{{ user.role }}</td>
                            <td>{{ formatedDate(user.created_at) }}</td>
                            <td>{{ formatedDate(user.updated_at) }}</td>
                            <td>
                                <a
                                    class="icon-edit"
                                    href="#"
                                    data-bs-toggle="modal"
                                    @click.prevent="editResource(user)"
                                >
                                    <i class="bi bi-pencil-fill"></i>
                                </a>
                            </td>
                            <td>
                                <a
                                    v-if="user.role == 0"
                                    class="icon-edit"
                                    href="#"
                                    @click.prevent="validateResource(user)"
                                >
                                    <i class="bi bi-check-square"></i>
                                </a>
                            </td>
                            <td>
                                <a
                                    class="icon-edit"
                                    href="#"
                                    @click.prevent="useUsers.deleteResource(user.id)"
                                >
                                    <i class="bi bi-trash"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


        <MyModal
            v-bind:idProps="state.modal.edit"
        >
            <!--    Content for header slot     -->
            <template v-slot:header>
                {{ state.modal.header }}
            </template>

            <template v-slot:body>

                <!-- Display server error message from database -->
                <ExceptionMessage
                    v-bind:exception="useUsers.useErrors.state.exception"
                    v-bind:exceptionMessage="useUsers.useErrors.state.exceptionMessage"
                ></ExceptionMessage>

                <div class="form-floating mb-3">
                    <input
                        type="text"
                        class="form-control rounded-4"
                        id="name"
                        v-bind:class="formUtilsComp.isFieldEmpty(useUsers.state.form.body.name) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="name"
                        v-model="useUsers.state.form.body.name"
                    >
                    <label for="name">Username</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isFieldEmpty(useUsers.state.form.body.name)" class="text-info text-sm">Please fill up with your name</div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useUsers.useErrors.state.errors.name"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <input
                        type="email"
                        class="form-control rounded-4"
                        id="email"
                        v-bind:class="formUtilsComp.isEmailValid(useUsers.state.form.body.email) ? 'is-valid' : 'is-invalid'"
                        @keyup.enter="saveResource()"
                        placeholder="name@example.com"
                        v-model="useUsers.state.form.body.email"
                    >
                    <label for="email">Email address</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="!formUtilsComp.isEmailValid(useUsers.state.form.body.email)" class="text-info text-sm">Please enter a valid email address</div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useUsers.useErrors.state.errors.email"></ValidationErrors>
                </div>

                <div v-if="state.modal.action !== 'edit'" class="form-floating mb-3">
                    <input
                        :type="useUsers.state.form.show_password ? 'text' : 'password'"
                        class="form-control rounded-4"
                        id="password"
                        v-bind:class="formUtilsComp.isPasswordEmpty(useUsers.state.form.body.password) ? 'is-invalid' : 'is-valid'"
                        @keyup.enter="saveResource()"
                        placeholder="Password"
                        v-model="useUsers.state.form.body.password"
                    >
                    <label for="password">Password</label>
                    <!-- Diplay frontend application alert message -->
                    <div v-if="formUtilsComp.isPasswordEmpty(useUsers.state.form.body.password)" class="text-info text-sm">Please enter your password</div>
                    <!-- Diplay alert message received from backend -->
                    <ValidationErrors v-bind:errors="useUsers.useErrors.state.errors.password"></ValidationErrors>
                </div>

                <div class="form-floating mb-3">
                    <select
                        class="form-select rounded-4"
                        id="role"
                        v-model="useUsers.state.form.body.role"
                        @keyup.enter="saveResource()"
                    >
                        <option value="">Select role...</option>
                        <option v-for="option in useUsers.state.form.options" :value="option.value" v-bind:key="option.value">
                            {{ option.text }}
                        </option>

                    </select>
                    <label for="role">Role</label>
                </div>

                <div v-if="state.modal.action !== 'edit'" class="form-floating mb-3">
                    <div class="checkbox mb-1">
                        <label>
                            <input
                                v-model="useUsers.state.form.show_password"
                                @keyup.enter="saveResource()"
                                type="checkbox"
                                value="show-password"
                            > Show password
                        </label>
                    </div>
                </div>


                <button
                    @click="saveResource()"
                    class="w-100 mb-2 btn btn-lg rounded-4 btn-primary"
                >Save</button>


            </template>

        <!--
            <template v-slot:footer>
                <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 border-right"><strong>Submit</strong></button>
                <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0" data-bs-dismiss="modal">Cancel</button>
            </template>
        -->
        </MyModal>

    </div>
</template>

<script>
// import Vue.js framework functions
import { reactive } from 'vue'
import { onMounted } from 'vue'

// import external functionalities
import { Modal } from 'bootstrap';

// import application's components
import MyModal from '@/components/MyModal.vue'

// import composables files
import useObjectsUtils from '@/composables/useObjectsUtils.js'
import useDateTimeUtilsComp from '@/composables/useDateTimeUtilsComp.js'
import useFormUtilsComp from '@/composables/useFormUtilsComp.js'
import useUsersComp from '@/composables/useUsersComp.js'

// import informations and errors messages components
import AltertMessage from '@/components/messages/altertMessage.vue'
import ExceptionMessage from '@/components/messages/exceptionMessage.vue'
import ValidationErrors from '@/components/messages/validationErrors.vue'

//import moment from 'moment'


export default {

    name: 'users',

    components : {
        MyModal,
        AltertMessage,
        ValidationErrors,
        ExceptionMessage,
    },


    setup() {
        const objUtils = useObjectsUtils()
        const dateTime = useDateTimeUtilsComp()
        const useUsers = useUsersComp()
        const formUtilsComp = useFormUtilsComp()

        //const momentFunctions = moment
        //const internalInstance = reactive( getCurrentInstance() )

        const state = reactive({
            modal: {
                //id: 'userModal',
                edit: 'editResourceModal',
                alert: 'alertModal',
                header: '',
                action: '',
                component: null,
                componentEdit: null,
                componentAlert: null
            },
            lang: 'fr',
            nextLang: 'en',
        });

        onMounted(() => {
            useUsers.getUsers()
            //useUsers.state.modal.component = new Modal(document.getElementById('myModalId'))
            //state.modal.component = new Modal(document.getElementById(state.modal.id))
            state.modal.componentEdit = new Modal(document.getElementById(state.modal.edit))
            state.modal.componentAlert = new Modal(document.getElementById(state.modal.alert))
        });


        // Return formated date to display
        function formatedDate(date) {
            return dateTime.formatedDate(date, state.lang, "LLLL")
        }

        // Change display language in component
        function changeLang() {
            if (state.nextLang == 'en') {
                state.lang = 'en'
                state.nextLang = 'fr'
            } else {
                state.lang = 'fr'
                state.nextLang = 'en'
            }
        }

        // Erase resource data before insertion in form
        function createResource() {

            // Define modal attributes
            state.modal.header = 'Create new user'
            state.modal.action = 'create'

            // Open Modal component
            state.modal.componentEdit.toggle()

            // Erase error messages
            //formUtilsComp.useResetErrors()
            useUsers.useErrors.useResetErrors()

            // Flush user's attributes
            useUsers.flushAttributes()
        }

        // Clone resource to update before insertion in form
        function editResource(resource) {

            // Define modal attributes
            state.modal.header = 'Edit user'
            state.modal.action = 'edit'

            // Open Modal component
            state.modal.componentEdit.toggle()

            // Erase error messages
            //formUtilsComp.useResetErrors()
            useUsers.useErrors.useResetErrors()

            // Clone user's attributes
            useUsers.state.form.body = objUtils.cloneObject(resource)
        }

        function validateResource(resource) {

            // Clone user's attributes
            useUsers.state.form.body = objUtils.cloneObject(resource)

            // Set user's role to 1
            useUsers.state.form.body.role = 1

            // Save validated resource
            useUsers.updateResource()
            .then(() => {
                useUsers.state.form.response.alert = 'alert-success'
            })
            .catch(() => {
                useUsers.state.form.response.alert = 'alert-danger'
            })
            .then(() => {
                // Open Modal component
                state.modal.componentAlert.toggle()
                // Reset message after a few milliseconds
                setTimeout(() => {
                    state.modal.componentAlert.toggle()
                }, 2000)
            })

        }

        // Save new created or updated resource
        function saveResource() {

            if ( useUsers.state.form.body.id == null ) {

                useUsers.storeResource()
                .then(() => {
                    // Close Modal component
                    state.modal.componentEdit.toggle()
                })
                .catch((error) => {
                    useUsers.useErrors.useSetErrors(error.response.data)
                })
            } else {
                useUsers.updateResource()
                .then(() => {
                    // Close Modal component
                    state.modal.componentEdit.toggle()
                })
                .catch((error) => {
                    useUsers.useErrors.useSetErrors(error.response.data)
                })
            }
        }

        return {
            state,
            useUsers,
            formUtilsComp,
            createResource,
            editResource,
            saveResource,
            validateResource,
            formatedDate,
            changeLang
        }
    }
}
</script>

<style>
    @import '../../assets/css/logo.css';
</style>
